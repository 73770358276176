$(function () {
    var logo = $('.navbar-brand img');

    window.addEventListener('scroll', function () {
        if (window.scrollY > 50) {
            document.getElementById('navbar_top').classList.add('fixed-top');
            // add padding top to show content behind navbar
            navbar_height = document.querySelector('.navbar').offsetHeight;
            document.body.style.paddingTop = navbar_height + 'px';
            $('.navbar-brand img').attr('src', logo.data('colorSrc'));
        } else {
            document.getElementById('navbar_top').classList.remove('fixed-top');
            // remove padding top from body
            document.body.style.paddingTop = '0';
            $('.navbar-brand img').attr('src', logo.data('whiteSrc'));
        }
    });

    lazyload();

    $.fn.select2.defaults.set("theme", "bootstrap");

    $('.select2').select2({
        dropdownParent: $('#loginModal')
    });

    $('#registerForm').on('submit', function () {
        $('#regBtn').prop('disabled', true);
    });

    $('#loginForm').on('submit', function () {
        $('#loginBtn').prop('disabled', true);
    });

    $('body').on('click', '#toRegister', function (e) {
        e.preventDefault();
        $('#loginForm').slideUp();
        $('#forgotPwdForm').slideUp();
        $('#registerForm').slideDown();
    });

    $('body').on('click', '.toLogin', function (e) {
        e.preventDefault();
        $('#registerForm').slideUp();
        $('#forgotPwdForm').slideUp();
        $('#loginForm').slideDown();
    });

    $('body').on('click', '#toForgotPwd', function (e) {
        e.preventDefault();
        $('#registerForm').slideUp();
        $('#loginForm').slideUp();
        $('#forgotPwdForm').slideDown();
    });

    $('#loginModal').on('hidden.bs.modal', function () {
        $('#registerForm .alert').removeClass('alert-success alert-warning alert-danger').hide();
        $('#registerForm').hide();
        $('#loginForm').show();
    });

    $('.expand-shadow-hover').on('mouseenter', function () {
        $(this).addClass('shadow-lg');
    }).on('mouseleave', function () {
        $(this).removeClass('shadow-lg');
    });
});